$green: #2ec07a;
$yellow: #f2bf37;
$box: #1e3541;
$box_hover: #213c4b;
$box_shadow: #0e1f29;
$gray: #adc3ce;
$gray_shadow: #6d8893;


.winner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 95;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFF;
    transition: all .3s ease-in-out;

    .winner_box {
        width: 100%;
        height: auto;
        padding: 50px 0;
        background: $box;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        transition: all .3s ease-in-out;
        opacity: 0;
        margin: -90px 0 0 0;

        span {
            color: $gray;
        }

        p {
            font-size: 40px;
            &.player1 { color: $green; }
            &.player2 { color: $yellow; }
        }

        div {
            line-height: 50px;
            padding: 0 40px;
            border-radius: 10px;
            background: $yellow;
            box-shadow: 0px 6px 0 #d1a326;
            color: #333;
            display: inline-block;
            cursor: pointer;
        }
    }

    &.entering, &.entered {
        background: rgba(0, 0, 0, .4);
        .winner_box {
            opacity: 1;
            margin: 0;
        }
    }
}

.player_names {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 90;
    background: $box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFF;

    input {
        width: calc(90% - 30px);
        max-width: calc(400px - 30px);
        height: 40px;
        background: #18282f;
        border: none;
        outline: none;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 0 15px;
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    p {
        font-weight: bold;
        color: $green;
        font-size: 16px;
        &.second_player {
            color: $yellow;
        }
    }

    .default_btn {
        background: $gray;
        color: #333;
        line-height: 40px;
        padding: 0 30px;
        border-radius: 10px;
        box-shadow: 0px 6px 0 $gray_shadow;
        font-weight: bold;
        cursor: pointer;
        transition: all .3s ease-in-out;
        &:hover {
            opacity: .8;
        }
    }
}
.bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #18282f;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        width: 500px;
        height: auto;
        overflow: auto;

        .top {
            width: calc(100% - 30px);
            height: auto;
            display: flex;
            justify-content: space-between;
            padding: 15px;
            font-family: Arial, Helvetica, sans-serif;

            .turn_info {
                width: auto;
                min-width: 33%;
                font-size: 28px;
                font-weight: bold;
                line-height: 46px;
                height: 46px;
                border-radius: 10px;

                &.player1 {
                    color: $green;
                }
                &.player2 {
                    color: $yellow;
                }
            }

            .restart_btn {
                height: 46px;
                padding: 0 20px;
                border-radius: 10px;
                background: $gray;
                box-shadow: 0px 6px 0 $gray_shadow;
                color: #333;
                font-weight: bold;
                line-height: 46px;
                cursor: pointer;
                transition: all .3s ease-in-out;
                &:hover {
                    @media screen and (min-width: 1200px) {
                        opacity: .8;
                    }
                }

                svg {
                    width: 22px;
                    height: 22px;
                    vertical-align: middle;
                    padding-right: 8px;
                    g {
                        fill: #375c70;
                    }
                }
            }
        }

        .game_summary {
            width: calc(100% - 30px);
            height: auto;
            display: flex;
            padding: 15px;
            font-family: Arial, Helvetica, sans-serif;

            .block {
                width: calc(50% - 15px);
                height: auto;
                background: $green;
                border-radius: 10px;
                padding: 15px;
                text-align: center;
                &:first-child {
                    margin-right: 30px;
                }
                &:last-child {
                    background: $yellow;
                }

                p {
                    font-family: 14px;
                    font-weight: bold;
                    color: #333;
                    margin: 0 0 10px 0;
                }
                h5 {
                    font-weight: bold;
                    margin: 0;
                    font-size: 30px;
                    color: #333;
                }
            }
        }

        .game_area {
            width: 100%;
            height: auto;
            box-sizing: border-box;

            .box_container {
                width: 33%;
                height: 0;
                padding-bottom: 33%;
                float: left;
                position: relative;
                margin-bottom: 8px;

                .box {
                    width: 88%;
                    height: 88%;
                    position: absolute;
                    left: 6%;
                    top: 6%;
                    z-index: 10;
                    background: $box;
                    border-radius: 16px;
                    box-shadow: 0px 8px 0 $box_shadow;
                    cursor: pointer;
                    transition: all .3s ease-in-out;
                    &:hover:not(.add_cyrcle, .add_times) {
                        background: $box_hover;
                    }
                }
                &.add_cyrcle, &.add_times {
                    .box {
                        background: $box !important;
                    }
                }

                .cyrcle {
                    width: 60%;
                    height: 60%;
                    position: absolute;
                    left: 20%;
                    top: 20%;
                    z-index: 11;
                    pointer-events:none;
                    opacity: 0;
                    
                    .right {
                        width: 50%;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 12;
                        overflow: hidden;
                        &::before {
                            width: calc(200% - 28px);
                            height: calc(100% - 28px);
                            content: '';
                            transform:rotate(45deg);
                            border-radius: 50%;
                            position: absolute;
                            right: 1px;
                            top: 0;
                            z-index: 20;
                            border: 14px solid $yellow;
                            border-left: 14px solid rgba(0, 0, 0, 0);
                            border-bottom: 14px solid rgba(0, 0, 0, 0);
                            transform:rotate(-135deg);
                            transition: all .2s ease-in;
                        }
                    }
                    
                    .left {
                        width: 50%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 12;
                        overflow: hidden;
                        &::before {
                            width: calc(200% - 28px);
                            height: calc(100% - 28px);
                            content: '';
                            transform:rotate(45deg);
                            border-radius: 50%;
                            position: absolute;
                            left: 1px;
                            top: 0;
                            z-index: 20;
                            border: 14px solid $yellow;
                            border-left: 14px solid rgba(0, 0, 0, 0);
                            border-bottom: 14px solid rgba(0, 0, 0, 0);
                            transition: all .2s ease-out .2s;
                        }
                    }
                }
                &.add_cyrcle {
                    .cyrcle {
                        opacity: 1;
                        .right::before {
                            transform:rotate(45deg);
                        }
                        .left::before {
                            transform:rotate(225deg);
                        }
                    }
                }

                .times {
                    width: 60%;
                    height: 60%;
                    position: absolute;
                    left: 20%;
                    top: 20%;
                    z-index: 11;
                    pointer-events:none;
                    &::before {
                        width: 0;
                        height: 18px;
                        content: '';
                        background: $green;
                        position: absolute;
                        left: 15%;
                        top: 5%;
                        z-index: 10;
                        transform:rotate(45deg);
                        transition: all .2s ease-out .2s;
                        border-radius: 10px;
                    }
                    &::after {
                        width: 0;
                        height: 18px;
                        content: '';
                        background: $green;
                        position: absolute;
                        right: 15%;
                        top: 5%;
                        z-index: 10;
                        transform:rotate(-45deg);
                        transition: all .2s ease-out;
                        border-radius: 10px;
                    }

                }
                &.add_times {
                    .times {
                        &::before {
                            width: 100%;
                            left: 0;
                            top: 40%;
                        }
                        &::after {
                            width: 100%;
                            right: 0;
                            top: 40%;
                        }
                    }
                }
            }
        }
    }
}